import type { ImageConfig } from '@archipro-design/aria';
import {
    archiproImageLoader,
    DEVICE_SIZES,
    IMAGE_SIZES,
} from '@archipro-design/aria';

export const LOCAL_IMAGE_DOMAIN = 'design.archipro.co.nz';

export interface ImageConfigProps {
    notEscapeURLParams?: boolean;
}

export const imageConfig = (props?: ImageConfigProps): ImageConfig => ({
    deviceSizes: [384, ...DEVICE_SIZES],
    imageSizes: IMAGE_SIZES,
    loader: archiproImageLoader('', 80, props?.notEscapeURLParams),
    isProduction: import.meta.env.PROD,
});
